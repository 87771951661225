@tailwind base;

@tailwind components;

.prose {
  max-width: 85ch;
}


.text-shadow {
  text-shadow: 0px 0px 0 rgba(0, 0, 0, 0.35);
}

.gallery-item:hover .caption {
  @apply opacity-75;
}

.gallery-item:hover .caption:hover {
  @apply opacity-100;
}

.rich-text-default p {
  margin-bottom: 1rem;
}


.text-shadow-readable {
  text-shadow: 1px 1px 3px rgb(0 0 0);
}


.checkbox-label::before,
.checkbox-label::after {
  content: '';
  width: theme('width.8');
  height: theme('height.8');
  position: absolute;
  @apply rounded-full;
  @apply block;
  background: theme('colors.white');
  left: 0;
  top: theme('width.1');
}

.checkbox:checked+.checkbox-label::after {
  color: theme('colors.urbact.light');
  content: "✔";
  text-align: center;
  @apply text-3xl;
  @apply p-1;
  @apply flex;
  @apply items-center;
}

.checkbox.required:invalid+.checkbox-label::before {
  border: 1px solid theme('colors.red.500');
  box-shadow: 0 0 2px inset theme('colors.red.400'), 0 0 2px theme('colors.red.400');
}

.toggle-checkbox:checked {
  @apply right-0;
  @apply border-urbact-light;
}

.toggle-checkbox:checked+.toggle-label {
  @apply bg-urbact-light;
}


@screen md {

  .h-50-mt4 {
    height: calc(50% - theme('spacing.4'));
  }

  .h-full-mt4 {
    height: calc(100% + theme('spacing.4'));
  }

  .columns {
    columns: 3;
    column-gap: 3rem;
  }

  .columns-2 {
    columns: 2;
    column-gap: 3rem;
  }

  .hover\:z-10:hover {
    z-index: 10;
  }
}

@tailwind utilities;

@screen md {
  .text-columns {
    columns: 2;
    column-gap: 4rem;
  }
}

.swiper-slide-thumb-active {
  border-color: theme('colors.urbact.light');
}

.swiper-slide-thumb-active.swiper-slide {
  opacity: 1;
}

[x-cloak] {
  display: none;
}

a.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

.marking mark {
  background-color: transparent;
  color: theme('colors.urbact.light');
}

#ScrollMenu .active a {
  @apply bg-gray-900 text-white px-3 py-2;
}

html {
  scroll-behavior: smooth;
}
